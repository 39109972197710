import {Component, inject, OnInit, signal} from '@angular/core';
import {InputComponent} from '@shared/components/forms/components/input/input.component';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule,} from '@angular/forms';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ModalService} from '@core/services/modal.service';
import {CoatoSelectorModalComponent} from '@app/common/coato-selector/coato-selector-modal/coato-selector-modal.component';
import {ReferenceBranchesService} from '@control-panel/components/unused-pages/reference/services/reference-branches.service';
import {CoatoCodeService} from './services/coato.service';
import {AllowedCoatoListService} from './services/allowed-coato-list.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {ToastService} from '@core/services/toast.service';
import {ROLES} from '@core/enums/Roles.enum';
import {map} from 'rxjs/operators';
import {CoatoBranchesDetailService} from './services/coato-branches-detail.service';
import {LiveCallsDataService} from '../customer-evaluation/live-calls-data.service';

@Component({
  selector: 'app-coato-selector',
  templateUrl: './coato-selector.component.html',
  styleUrl: './coato-selector.component.scss',
  standalone: true,
  imports: [
    InputComponent,
    FormsModule,
    NgxMaskDirective,
    KeenIconComponent,
    ReactiveFormsModule,
  ],
  providers: [provideNgxMask()],
})
export class CoatoSelectorComponent implements OnInit {
  myForm: FormGroup = new FormGroup({
    code: new FormControl(''),
    name: new FormControl(null),
  });

  _formBuilder = inject(FormBuilder);
  _spravochnikBranchesService = inject(ReferenceBranchesService);
  _coatoCodeService = inject(CoatoCodeService);
  _allowedCoatoListService = inject(AllowedCoatoListService);
  _coatoBranchesDetailService = inject(CoatoBranchesDetailService);
  _translate = inject(TranslateService);
  openModalBtn = true;
  removeBtn = false;
  _modalService = inject(ModalService);
  _router = inject(Router);
  _auth = inject(AuthService);
  toastService = inject(ToastService);
  protected isAdminOrDepHead = signal(false);
  private _liveCallsDataService = inject(LiveCallsDataService);
  private refreshTimeoutId: any;
  private currentUser: any;
  private districtsCodes: string[] = [];
  private showWarning: number = 0;

  constructor() {
    this._auth.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

    if (this.currentUser && this.currentUser?.roles[0]?.name) {
      const role = this.currentUser.roles[0].name;
      if (role === ROLES.ADMIN || role === ROLES.DEPARTMENT_HEAD) {
        this.isAdminOrDepHead.set(true);
      }
    }
  }

  ngOnInit(): void {
    if (this._auth.currentUserValue) {
      if (!this.isAdminOrDepHead()) {
        this.getCurUserCOATO();
      } else {
        this.getLocalStorage();
      }
      this.getCoatoSubjectData();
      // this.getDistrict();
    }

  }

  getCurUserCOATO() {
    const code = this._coatoCodeService.getCoatoCode() ? this._coatoCodeService.getCoatoCode() : this.currentUser.coato_code;
    if (this.myForm.controls.code.value !== code) {
      this.myForm.patchValue({
        code: code,
      });
      this.openModalBtn = false;
      this.removeBtn = true;
      this.getCoatoInputData(code);
    }
  }

  getLocalStorage() {
    const code = this._coatoCodeService.getCoatoCode();
    if (this.myForm.controls.code.value !== code) {
      this.myForm.patchValue({
        code: code,
      });
      this.openModalBtn = false;
      this.removeBtn = true;
      this.getCoatoInputData(this._coatoCodeService.getCoatoCode());
    }
  }

  searchData(inputValue: any) {
    if ((!this.isAdminOrDepHead() ? this.districtsCodes.includes(inputValue) : true) || true) {

      if (inputValue) {
        this.openModalBtn = false;
        this.removeBtn = true;
      } else {
        this.removeBtn = false;
        this.openModalBtn = true;
      }

      if (inputValue.length === 5) {
        this._coatoCodeService.setCoatoCode(inputValue);
        this.getCoatoInputData(inputValue);
        if (this.refreshTimeoutId) {
          clearTimeout(this.refreshTimeoutId);
        }

        this.refreshTimeoutId = setTimeout(() => {
          this.refreshPage();
        }, 400);

      } else {
        this.myForm.patchValue({
          name: null,
        });
      }
    } else {
      //AGENT va OPERATORlar o'ziga tegishli bo'lmagan
      // COATO yozganda shu qismga tushadi

      if (inputValue.length === 5 && this.showWarning === 0) {
        this.toastService.warning('NotFoundCOATO');
        this.showWarning++;
      }
      if (inputValue.length !== 5) {
        this.showWarning = 0;
      }
    }

  }

  getDistrict() {
    this._allowedCoatoListService.getAll({parent_code: this.currentUser?.coato_code, per_page: 200}).pipe(
      map((value) => {
        const _districts = value?.data?.data.map((item) => item.code);

        if (this.currentUser?.coato_code) {
          _districts.push(this.currentUser.coato_code);
        }

        return _districts;
      })
    ).subscribe(
      (districts) => {
        this.districtsCodes = districts;
      },
      (error) => {
        console.log(error);
        this.toastService.warning(this._translate.instant('NotBeenDefinedCOATO'));
      }
    );
  }


  ngOnDestroy(): void {
    if (this.refreshTimeoutId) {
      clearTimeout(this.refreshTimeoutId);
    }
  }

  refreshPage() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.navigate([this._router.url], {skipLocationChange: true});

    this._coatoCodeService.isCoatoChange.set(true);
  }

  getCoatoInputData(code) {
    this._coatoBranchesDetailService.getAll({code: code}).subscribe({
      next: (value) => {
        if (value?.data?.data[0]) {
          value = value?.data?.data[0];

          this._liveCallsDataService.sendCoatodata(value);

          this.myForm.patchValue({
            code: value?.code,
            name: value?.name,
          });
          this._coatoCodeService.removeCoatoCode();
          this._coatoCodeService.removeParentCode();

          this._coatoCodeService.setCoatoCode(value?.code);
          this._coatoCodeService.setCoatoName(value?.name);
          this._coatoCodeService.setParentCode(value?.parent_code);
        } else {
          this.myForm.patchValue({
            code: code,
            name: null,
          });

          this.toastService.error('CoatoNotFoundToastTitle');
        }
        this.openModalBtn = false;
        this.removeBtn = true;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getCoatoSubjectData() {
    this._spravochnikBranchesService.getCoatoData().subscribe((data: any) => {
      if (data) {
        this.openModalBtn = false;
        this.removeBtn = true;
        this.myForm.patchValue({
          code: data.code,
          name: data.name,
        });
      } else {
        this.openModalBtn = true;
        this.removeBtn = false;
        this.myForm.patchValue({
          code: null,
          name: null,
        });
      }
    });
  }

  openSelectorModal() {
    this._modalService.open({
      component: CoatoSelectorModalComponent,
      title: this._translate.instant('Components.Dashboard.SelectRegion'),
      modalOptions: {
        size: 'lg',
      },
    });
  }

  clearInput() {
    this.myForm.patchValue({
      code: null,
      name: null,
    });
    this._coatoCodeService.removeCoatoCode();
    this._coatoCodeService.removeCoatoName();
    this.openModalBtn = true;
    this.removeBtn = false;
    // location.reload();
    this.refreshPage();
  }

  disabled(event: Event) {
    event.preventDefault();
  }
}
