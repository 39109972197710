import {Component, inject, OnInit, signal} from '@angular/core';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ModalService} from '@core/services/modal.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CustomerEvaluationModalComponent} from './customer-evaluation-modal/customer-evaluation-modal.component';
import {LiveCallsDataService} from './live-calls-data.service';
import {ToastService} from '@core/services/toast.service';
import {ROLES} from '@core/enums/Roles.enum';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {MyLiveCallService} from '@call-center/services/my-live-call.service';

@Component({
  selector: 'app-customer-evaluation',
  templateUrl: './customer-evaluation.component.html',
  styleUrl: './customer-evaluation.component.scss',
  standalone: true,
  imports: [KeenIconComponent, TranslateModule],
})
export class CustomerEvaluationComponent implements OnInit {
  _modalService = inject(ModalService);
  _translate = inject(TranslateService);
  public _toastService = inject(ToastService);
  callID = signal(null);
  visible = signal(false);
  role = signal(false);
  allowCoato = signal(false);
  private _myLiveCallService = inject(MyLiveCallService);

  constructor(private _liveCallsDataService: LiveCallsDataService, private _authService: AuthService) {
    this._liveCallsDataService.VisibleData.subscribe((res) => {
      this.visible.set(res);
    });

    this._authService.currentUser.subscribe((res: any) => {
      if (res?.roles[0]?.name != ROLES.AGENT) {
        this.role.set(true);
      } else {
        this.role.set(false);
      }
    });

    this._liveCallsDataService.CoatoData.subscribe((res: any) => {
      if (res?.parent_code && res.parent_code != '00000') {
        this.allowCoato.set(true);
      } else {
        this.allowCoato.set(false);
      }
    });

    this._liveCallsDataService.LivecallsData.subscribe((res: any) => {
      if (res?.success && res?.data?.call_events?.length > 0) {
        this.callID.set(res?.data?.call_events[0].call_id);
      } else if (res?.status == 404) {
        this.callID.set(null);
      }
    });
  }

  ngOnInit(): void {

  }

  clickedBtn() {
    this._myLiveCallService.getAll().subscribe({
      next: (res: any) => {
        if (res?.data?.call_events[0]?.call_id) {
          this.openEvaluationModal(res?.data?.call_events[0]?.call_id);
        } else {
          this._toastService.error('Components.CallCenter.CallIsNotAvailable');
        }

      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  openEvaluationModal(id) {
    if (this.allowCoato()) {
      this._modalService.open({
        component: CustomerEvaluationModalComponent,
        title: this._translate.instant('Components.CallCenter.CallEvaluation'),
        modalOptions: {
          modalDialogClass: 'call-evaluation-content',
        },
        componentOptions: {
          myCallId: id,
        }
      });
    } else {
      this._toastService.error('ERROR_LIST.COATO_REQUIRED_TEXT');
    }
  }
}
