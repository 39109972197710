<ng-container *ngIf="!item.hidden">
  <!-- item.url -->
  <a
    *ngIf="item.url && !item.externalUrl"
    [ngClass]="item.classes"
    [routerLink]="[item.url]"
    [target]="item.openInNewTab ? '_blank' : '_self'"
    class="dropdown-item d-flex align-items-center"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a
    *ngIf="item.url && item.externalUrl"
    [href]="item.url"
    [ngClass]="item.classes"
    [target]="item.openInNewTab ? '_blank' : '_self'"
    class="dropdown-item d-flex align-items-center"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <keen-icon [name]="item.icon" class="font-medium-4 mr-50" type="outline"></keen-icon>
    <span class="font-weight-bold">{{ item.translate | translate }}</span>
  </ng-template>
</ng-container>
