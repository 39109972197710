import {Component, ElementRef, HostListener, Input, OnInit,} from '@angular/core';
import {KeenIconComponent} from '@app/shared/components/keen-icon/keen-icon.component';
import {CoreCommonModule} from '@core/common.module';
import {CoreMenuHorizontalItemComponent} from '../item/item.component';
import {animate, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';

@Component({
  selector: '[dropdown-menu-collapsible]',
  standalone: true,
  imports: [
    CoreCommonModule,
    KeenIconComponent,
    CoreMenuHorizontalItemComponent,
  ],
  templateUrl: './dropdown-menu.component.html',
  styleUrl: './dropdown-menu.component.scss',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(-10px)'}),
        animate('0.2s', style({opacity: 1, transform: 'translateY(0)'}))
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({opacity: 1, transform: 'translateY(0)'}),
        animate('0.2s', style({opacity: 0, transform: 'translateY(-10px)'}))
      ]),
    ]),
  ]
})
export class DropdownMenuCollapsibleComponent implements OnInit {
  @Input()
  item: any;
  isShow = false;
  protected readonly Array = Array;

  constructor(private el: ElementRef, private router: Router) {
  }

  ngOnInit(): void {
  }

  @HostListener('mouseenter') show(): void {
    this.isShow = true;
    this.setSubMenuProp();
  }

  @HostListener('mouseleave')
  hide(): void {
    this.isShow = false;
  }

  setSubMenuProp(): void {
  }

  isActive() {
    if (this.item?.children?.length > 0) {
      return this.check(this.item.children);
    }
    return false;
  }

  isItemActive(item: any) {
    return this.check([item]);
  }

  check(items: any) {
    const url = this.router.url;
    for (const item of items) {
      if (item?.children?.length > 0 && this.check(item.children)) {
        return true;
      }
      if ('/' + item.url === url) {
        return true;
      }
    }
  }
}
