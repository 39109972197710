<div class="input-menu-container">
  <form [formGroup]="myForm" class="d-flex align-items-center">
    <div class="first-input d-flex align-items-center">
      <input
        (blur)="getLocalStorage()"
        (input)="searchData($event.target.value)"
        (keydown.enter)="disabled($event)"
        [mask]="'00000'"
        [maxlength]="5"
        formControlName="code"
        placeholder="COATO"
        type="text"
      />
      <button (click)="openSelectorModal()" class="open-modal-btn">
        <keen-icon
          color="dark"
          name="dots-vertical"
          size="large-1"
          type="outline"
        ></keen-icon>
      </button>
      <!--      @if (removeBtn) {-->
      <!--        <button class="remove-btn" (click)="clearInput()">-->
      <!--          <keen-icon-->
      <!--            name="cross"-->
      <!--            type="outline"-->
      <!--            size="medium-5"-->
      <!--            class="mr-0"-->
      <!--          ></keen-icon>-->
      <!--        </button>-->
      <!--      }-->
    </div>
    <div class="second-input input-content">
      <input formControlName="name" readonly type="text"/>
    </div>
  </form>
</div>
