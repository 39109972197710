@if (visible() && role()) {
  <div class="evaluation-container d-flex align-items-center" (click)="clickedBtn()">
    <keen-icon
      class="font-medium-4 mr-50"
      name="notepad-bookmark"
      type="outline"
    ></keen-icon>
    <p class="font-weight-bold">{{ 'Menu.Evaluation' | translate }}</p>
  </div>
}
