@if (!item.hidden) {
  <ng-container>
    <!-- collapsible title -->
    @if (!item.url) {
      <div
        class="d-flex align-items-center cursor-pointer"
        [ngClass]="[
          item.type == 'section'
            ? 'dropdown-toggle nav-link'
            : 'dropdown-item dropdown-toggle',
            isActive() ? 'kep-active' : ''
        ]"
      >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </div>
    }

    <!-- itemContent template -->
    <ng-template #itemContent>
      <keen-icon
        class="font-medium-4 mr-50"
        type="outline"
        [name]="item.icon"
      ></keen-icon>
      <span class="font-weight-custom">{{ item.translate | translate }}</span>
    </ng-template>

    @if (isShow) {
      <div class="dropdown-menu-box" [@fadeIn] [@fadeOut]="isShow ? 'visible' : 'hidden'">
        @for (item of item['children']; track item.id) {
          <div class="dropdown-item-menu">
            <a [routerLink]="[item.url]" [ngClass]="{
              'bg-light-primary': isItemActive(item)
            }">
              <div class="dropdown-text-content d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <keen-icon
                    class="icon-content font-medium-4 mr-50"
                    type="outline"
                    [name]="item.icon"
                  ></keen-icon>
                  <span class="text-content text-nowrap font-weight-bold">{{ item.translate | translate }}</span>
                </div>
                @if (item.children) {
                  <keen-icon class="icon-content font-medium-4 mr-50" name="right"></keen-icon>
                }
              </div>
            </a>
            @if (Array.isArray(item.children)) {
              <div class="column-items">
                @for (item of item['children']; track item.id) {
                  <a [routerLink]="[item.url]" class="text-nowrap" [ngClass]="{
                    'bg-light-primary': isItemActive(item)
                  }">
                    <div class="dropdown-text-content d-flex align-items-center">
                      <span class="text-nowrap">{{ item.translate | translate }}</span>
                    </div>
                  </a>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </ng-container>
}
