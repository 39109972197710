<!-- Vertical Menu -->
<ng-container *ngIf="layout === 'vertical'">
  <ng-container *ngFor="let item of menu">
    <!-- section -->
    <li
      *ngIf="item.type == 'section'"
      [item]="item"
      class="navigation-header"
      core-menu-vertical-section
    ></li>

    <!-- sub-menu item/collapsible of sections  -->
    <ng-container *ngIf="item.type == 'section'">
      <ng-container *ngFor="let item of item.children">
        <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
        <li
          *ngIf="item.type == 'item'"
          [item]="item"
          [ngClass]="{ disabled: item.disabled === true }"
          [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
          [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
          core-menu-vertical-item
        >
          <span
            [routerLink]="item.openInNewTab ? [] : [item.url]"
            class="d-none"
          ></span>
        </li>
        <!-- collapsible -->
        <li
          *ngIf="item.type == 'collapsible'"
          [item]="item"
          class="nav-item has-sub"
          core-menu-vertical-collapsible
        ></li>
      </ng-container>
    </ng-container>

    <!-- item -->
    <li
      *ngIf="item.type == 'item'"
      [item]="item"
      [ngClass]="{ disabled: item.disabled === true }"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      core-menu-vertical-item
    >
      <span
        [routerLink]="item.openInNewTab ? [] : [item.url]"
        class="d-none"
      ></span>
    </li>
    <!-- collapsible -->
    <li
      *ngIf="item.type == 'collapsible'"
      [item]="item"
      class="nav-item has-sub"
      core-menu-vertical-collapsible
    ></li>
    <hr/>
  </ng-container>
</ng-container>
<!--/ Vertical Menu -->

<!-- Horizontal Menu -->
<ng-container *ngIf="layout === 'horizontal'">
  <ng-container *ngFor="let item of menu">
    @if (item.type == 'collapsible') {
      @if (layoutType == 'horizontal') {
        <li
          [item]="item"
          class="dropdown nav-item"
          dropdown-menu-collapsible
        ></li>
      } @else {
        <li
          [item]="item"
          class="dropdown dropdown-submenu"
          mega-menu-collapsible
        ></li>
      }
    }

    <li
      *ngIf="item.type == 'item'"
      [item]="item"
      [ngClass]="{ disabled: item.disabled === true }"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      core-menu-horizontal-item
    >
      <span
        [routerLink]="item.openInNewTab ? [] : [item.url]"
        class="d-none"
      ></span>
    </li>

  </ng-container>

  <div class="evaluation-container">
    <app-customer-evaluation></app-customer-evaluation>
  </div>

  <div class="coato-container d-flex align-items-center">
    <app-coato-selector/>
  </div>
</ng-container>
<!--/ Horizontal Menu -->
