<ng-container *ngIf="!item.hidden">
  <!-- collapsible title -->
  <a (click)="toggleOpen($event)" *ngIf="!item.url" [ngClass]="item.classes" class="d-flex align-items-center">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- itemContent template -->
  <ng-template #itemContent>
    <span *ngIf="item.icon" [data-feather]="item.icon"></span>
    <span class="menu-title text-truncate">{{ item.translate | translate }}</span>
    <span
      *ngIf="item.badge"
      [ngClass]="item.badge.classes"
      class="badge ml-auto mr-1"
    >
      {{ item.badge.translate | translate }}
    </span>
  </ng-template>

  <!-- sub-menu item/collapsible -->
  <ul class="menu-content">
    <ng-container *ngFor="let item of item.children">
      <!-- item -->
      <li
        *ngIf="item.type == 'item'"
        [item]="item"
        [ngClass]="{ disabled: item.disabled === true }"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
        core-menu-vertical-item
      >
        <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
      </li>
      <!-- collapsible -->
      <li *ngIf="item.type == 'collapsible'" [item]="item" class="nav-item has-sub" core-menu-vertical-collapsible></li>
    </ng-container>
  </ul>
</ng-container>
