@if (!item.hidden) {
  <ng-container>
    <!-- collapsible title -->
    @if (!item.url) {
      <div
        class="d-flex align-items-center cursor-pointer"
        [ngClass]="[
      item.type == 'section'
        ? 'dropdown-toggle nav-link'
        : 'dropdown-item dropdown-toggle'
    ]"
      >
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </div>
    }

    <!-- itemContent template -->
    <ng-template #itemContent>
      <keen-icon
        class="font-medium-4 mr-50"
        type="outline"
        [name]="item.icon"
      ></keen-icon>
      <span class="font-weight-custom">{{ item.translate | translate }}</span>
      @if (item.id == 'competitions' && 0) {
        <span class="badge ml-25 badge-yellow badge-pill badge-glow">New</span>
      }
    </ng-template>

    @if (isShow) {
      <div class="mega-menu-box" [@fadeIn] [@fadeOut]="isShow ? 'visible' : 'hidden'">
        <div class="items">
          @for (item of item['children']; track item.id) {
            <div>
              <a [routerLink]="[item.url]">
                <div class="mega-text-content d-flex align-items-center">
                  <keen-icon
                    class="font-medium-4 mr-50"
                    type="outline"
                    [name]="item.icon"
                  ></keen-icon>
                  <span class="text-nowrap">
                    {{ item.translate | translate }}
                  </span>
                </div>
              </a>
              @if (item['children']) {
                <div class="column-items">
                  @for (item of item['children']; track item.id) {
                    <a [routerLink]="[item.url]" class="text-nowrap">
                      <div class="mega-text-content d-flex align-items-center">
                        <span class="circle-box"></span>
                        <span class="text-nowrap">{{ item.translate | translate }}</span>
                      </div>
                    </a>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
  </ng-container>
}
