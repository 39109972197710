import {Component, inject, Input, OnInit, signal} from '@angular/core';
import {InputComponent} from '@shared/components/forms/components/input/input.component';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule,} from '@angular/forms';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {ModalService} from '@core/services/modal.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ToastService} from '@core/services/toast.service';
import {environment} from 'environments/environment';
import {HttpClient} from '@angular/common/http';
import {InputLabelComponent} from '@app/shared/components/forms/components/input-label/input-label.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {TreeSelectModule} from 'primeng/treeselect';
import {NgSelectModule} from '@ng-select/ng-select';
import {CoreCommonModule} from '@core/common.module';
import {HetTextareaComponent} from '@app/shared/components/forms/components/het-textarea/het-textarea.component';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {LiveCallsDataService} from '../live-calls-data.service';
import { CoatoCodeService } from '@app/common/coato-selector/services/coato.service';

@Component({
  selector: 'app-customer-evaluation-modal',
  templateUrl: './customer-evaluation-modal.component.html',
  styleUrl: './customer-evaluation-modal.component.scss',
  standalone: true,
  imports: [
    InputComponent,
    FormsModule,
    NgxMaskDirective,
    KeenIconComponent,
    ReactiveFormsModule,
    TranslateModule,
    InputLabelComponent,
    InputTextareaModule,
    TreeSelectModule,
    NgSelectModule,
    HetTextareaComponent,
    CoreCommonModule,
    ButtonComponent,
    MultiSelectModule,
  ],
  providers: [provideNgxMask()],
})
export class CustomerEvaluationModalComponent implements OnInit {
  @Input() myCallId: any;
  _modalService = inject(ModalService);
  _translate = inject(TranslateService);
  form: FormGroup;
  selectedPetetionTypesCount: number = 0;
  currentLang: string = null;
  currentName = signal('name_uz');
  callId = signal(null);
  coato_code = signal(null);
  selectedIncidents: any = [];
  incidentOptions: any;
  petitionTypes = [
    {
      value: '1',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.ReqType.Request'
      ),
    },
    {
      value: '2',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.ReqType.Complaint'
      ),
    },
    {
      value: '3',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.ReqType.Offer'
      ),
    },
    {
      value: '4',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.ReqType.Service'
      ),
    },
  ];
  userBehaviors = [
    {
      value: '1',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.UserType.Well'
      ),
    },
    {
      value: '2',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.UserType.Medium'
      ),
    },
    {
      value: '3',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.UserType.Rude'
      ),
    },
    {
      value: '4',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.UserType.VeryBad'
      ),
    },
    {
      value: '5',
      label: this._translate.instant(
        'Components.CallCenter.TableUserID.UserType.Lose'
      ),
    },
  ];
  private _http = inject(HttpClient);
  private _toastService = inject(ToastService);
  private coatoService = inject(CoatoCodeService);

  constructor(
    private fb: FormBuilder,
    private _liveCallsDataService: LiveCallsDataService
  ) {
    this._liveCallsDataService.CoatoData.subscribe((res: any) => {
      if (res?.parent_code != '00000') {
        this.coato_code.set(res?.code);
      }
    });

    this._liveCallsDataService.LivecallsData.subscribe((res: any) => {
      if (res?.success && res?.data?.call_events?.length > 0) {
        this.callId.set(res?.data?.call_events[0].call_id);
      }
    });
  }

  ngOnInit(): void {

    if (this.callId() == null) {
      this.callId.set(this.myCallId);
    }

    this.currentLang = this._translate.getDefaultLang();

    this.getCurrentLang();

    this.getIncidents();

    this.form = this.fb.group({
      petition_type: [''],
      note: [''],
      dealing: [''],
      incident: [''],
    });
  }

  onPetitionTypeChange(event): void {
    this.selectedPetetionTypesCount = event?.value?.length;
  }

  getIncidents(): void {
    this._http.get(environment.apiUrl + '/call-center/api/v1/incidents/selected-incidents').subscribe({
      next: (res: any) => {
        if (res.success) {
          this.incidentOptions = res?.data;
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }


  getCurrentLang() {
    switch (this.currentLang) {
      case 'uz':
        this.currentName.set('name_uz');
        break;
      case 'ru':
        this.currentName.set('name_ru');
        break;
      case 'crl':
        this.currentName.set('name_cryl');
        break;
      case 'kk':
        this.currentName.set('name_kk');
        break;
      default:
        this.currentName.set('name_uz');
    }
  }


  onSubmit(event) {
    const formData = {
      coato_code: this.coato_code(),
      call_id: this.callId(),
      petition_type: this.form.value.petition_type,
      note: this.form.value.note,
      dealing: this.form.value.dealing,
      incident: this.form?.value?.incident,
    };
    this._http
      .post(environment.apiUrl + '/call-center/api/v1/feedback', formData, {headers: {'Coato-Code': this.coatoService.getCoatoCode()}})
      .subscribe({
        next: (value) => {
          this._toastService.success('TOAST.TOAST_SUCCESS');
          this._modalService.close();
          this.form.reset();
        },
        error: (err) => {
          this._modalService.close();
          this.form.reset();

          console.log(err);

          this._toastService.success('ERROR_LIST.SERVER_ERROR');
        },
      });
  }
}
