import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CoreMenuService} from 'core/components/core-menu/core-menu.service';
import {CoreCommonModule} from '@core/common.module';
import {CoreMenuVerticalItemComponent} from '@core/components/core-menu/vertical/item/item.component';
import {CoreMenuHorizontalItemComponent} from '@core/components/core-menu/horizontal/item/item.component';
import {MegaMenuCollapsibleComponent} from './horizontal/mega-menu/mega-menu.component';
import {CoreConfigService} from '@core/services/config.service';
import {DropdownMenuCollapsibleComponent} from './horizontal/dropdown-menu/dropdown-menu.component';
import {CoatoSelectorComponent} from '@app/common/coato-selector/coato-selector.component';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {CoreMenuVerticalSectionComponent} from '@core/components/core-menu/vertical/section/section.component';
import {CoreMenuVerticalCollapsibleComponent} from '@core/components/core-menu/vertical/collapsible/collapsible.component';
import {CoreMenuHorizontalCollapsibleComponent} from '@core/components/core-menu/horizontal/collapsible/collapsible.component';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation,} from '@angular/core';
import {CustomerEvaluationComponent} from '@app/common/customer-evaluation/customer-evaluation.component';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CoreCommonModule,
    CoreMenuVerticalItemComponent,
    CoreMenuVerticalSectionComponent,
    CoreMenuVerticalCollapsibleComponent,
    CoreMenuHorizontalItemComponent,
    CoreMenuHorizontalCollapsibleComponent,
    MegaMenuCollapsibleComponent,
    DropdownMenuCollapsibleComponent,
    CoatoSelectorComponent,
    ButtonComponent,
    CustomerEvaluationComponent
  ],
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;
  @Input() layout = 'vertical';
  @Input() menu: any;
  layoutType: string;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _coreMenuService: CoreMenuService,
    private _coreConfigService: CoreConfigService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();
    this._coreConfigService.getConfig().subscribe((res) => {
      this.layoutType = res.layout.type;
    });

    this._coreMenuService.onMenuChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.currentUser = this._coreMenuService.currentUser;
        this.menu = this._coreMenuService.getCurrentMenu();
        this._changeDetectorRef.markForCheck();
      });
  }
}
