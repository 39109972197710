<div class="d-flex modal-body">
  <div class="w-50 pe-2">
    <form [formGroup]="form">
      <ups-input-label
        [label]="'Components.CallCenter.TableUserID.ReqType.Title'"
      ></ups-input-label>

      <ng-select
        (change)="onPetitionTypeChange($event)"
        [appendTo]="'body'"
        [clearable]="false"
        [items]="petitionTypes"
        bindLabel="label"
        bindValue="value"
        class="w-full mb-1"
        formControlName="petition_type"
        multiple="true"
      ></ng-select>

      <div class="mt-1"></div>

      <ups-input-label
        [label]="'Components.CallCenter.TableUserID.UserType.Title'"
      ></ups-input-label>

      <ng-select
        (change)="onPetitionTypeChange($event)"
        [appendTo]="'body'"
        [clearable]="false"
        [items]="userBehaviors"
        bindLabel="label"
        bindValue="value"
        class="w-full mb-1"
        formControlName="dealing"
        multiple="true"
      ></ng-select>
      <ups-input-label
      [label]="'Components.CallCenter.Call.Incident'"
    ></ups-input-label>
      <ng-select
        [appendTo]="'body'"
        [clearable]="false"
        [items]="incidentOptions"
        [bindLabel]="currentName()"
        bindValue="id"
        class="w-full mb-1"
        formControlName="incident"
        multiple="true"
        [maxSelectedItems]="2"
      ></ng-select>
    </form>
  </div>

  <div class="w-50 ps-2">
    <form [formGroup]="form">
      <div class="flex flex-col mb-1">
        <ups-input-label
          [label]="'Components.CallCenter.TableUserID.Description'"
        ></ups-input-label>

        <app-het-textarea
          [rows]="7"
          class="w-100"
          formControlName="note"
        ></app-het-textarea>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-end">
    <ups-button
      (btnClick)="onSubmit($event)"
      [label]="'Actions.Buttons.Submit'"
      [styleType]="'success'"
      [type]="'button'"
    ></ups-button>
  </div>
</div>
