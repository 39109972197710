import {Component, ElementRef, HostListener, Input, OnInit,} from '@angular/core';
import {KeenIconComponent} from '@app/shared/components/keen-icon/keen-icon.component';
import {CoreCommonModule} from '@core/common.module';
import {CoreMenuHorizontalItemComponent} from '../item/item.component';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: '[mega-menu-collapsible]',
  standalone: true,
  imports: [
    CoreCommonModule,
    KeenIconComponent,
    CoreMenuHorizontalItemComponent,
  ],
  templateUrl: './mega-menu.component.html',
  styleUrl: './mega-menu.component.scss',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(-10px)'}),
        animate('0.2s', style({opacity: 1, transform: 'translateY(0)'}))
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({opacity: 1, transform: 'translateY(0)'}),
        animate('0.2s', style({opacity: 0, transform: 'translateY(-10px)'}))
      ]),
    ]),
  ]
})
export class MegaMenuCollapsibleComponent implements OnInit {
  @Input()
  item: any;
  isShow = false;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
  }

  @HostListener('mouseenter') show(): void {
    this.isShow = true;
    this.setSubMenuProp();
  }

  @HostListener('mouseleave')
  hide(): void {
    this.isShow = false;
  }

  setSubMenuProp(): void {
  }
}
